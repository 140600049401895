<template>
  <b-container class="noticias">
    <b-container class="noticia" v-loading="loading">
      <img src="https://arquivos.aquidauana.ms.gov.br/pub/banner-destaque.jpg" v-if="!active" />
      <div v-if="active">
        <div class="noticia-title">
        <h3>{{ noticia.not_titulo }}</h3>
      </div>
      <div class="noticia-date pb-3">
        {{ noticia.not_data | formatDate("LL") }}
      </div>
      <div class="noticia-body">
        <div class="noticia-body__images">
          <img
            v-for="(item, index) in noticia.image"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div class="noticia-body__texto text-justify">
          <div v-html="noticia.not_texto"></div>
          <div class="noticia-body__fontes">
            <span class="text-font">Fonte: {{ noticia.not_fonte }}</span>
            <span v-if="noticia.not_creditos" class="text-font"
              >Crédito das Fotos: {{ noticia.not_creditos }}</span
            >
          </div>
          <div
            class="medias-share"
            v-if="Object.keys(noticia).length > 0 && url.length > 1"
          >
            <ShareNetwork
              network="whatsapp"
              :url="url"
              :title="noticia.not_titulo"
              description=""
              quote=""
              hashtags=""
              :media="url"
            >
              <img src="../assets/images/whats.png" alt="" height="30px" />
            </ShareNetwork>
            <ShareNetwork
              network="facebook"
              :url="url"
              :title="noticia.not_titulo"
              description=""
              quote=""
              hashtags=""
              :media="url"
            >
              <img src="../assets/images/fb1.png" alt="" height="30px" />
            </ShareNetwork>
          </div>
        </div>
      </div>
      </div>
    </b-container>
    <div class="list" v-loading="loadingDestaque" v-if="active">
      <Lista :destaque="destaque" @showId="showId" />
      <!-- <router-link to="/noticias" class="text-right">
        <small class="all">Ver todas as notícias</small>
      </router-link> -->
      <div class="see-all">
        <a href="/noticias" class="text-right"
          ><span class="all-links">Ver todas</span></a
        >
      </div>
    </div>
  </b-container>
</template>

<script>
import Lista from "@/components/destaque/Lista";
export default {
  name: "noticias",
  components: {
    Lista,
  },
  data() {
    return {
      noticia: {},
      destaque: [],
      loading: false,
      loadingDestaque: false,
      url: "",
      active: false,
    };
  },
  methods: {
    async getDestaques() {
      this.loadingDestaque = true;
      const res = await this.$axios.get("noticias").catch((e) => {
        console.error("noticias", e);
      });
      if (res.status === 200) {
        this.destaque = res.data.data;
      }
      this.loadingDestaque = false;
    },
    showId(id) {
      if (id.toString() === this.$route.params.id.toString()) return;
      this.toShow = this.destaque.filter((item) => item.not_cod === id)[0];
      this.getPage(id, this.slugify(this.toShow.not_titulo));
    },
    slugify(text) {
      return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
    },
    getPage(id, title) {
      // this.$router.push(`${`/noticia/${id}/${title}`}`);
      this.$router.push({
        path: `${`/noticia/${id}/${title}`}`,
        replace: true,
      });
    },
    async getNoticia(id) {
      this.loading = true;
      this.noticia = [];
      //const id = this.$route.params.id;
      const res = await this.$axios.get(`noticias/${id}`).catch((e) => {
        console.error("Noticia", e);
      });
      if (res.status === 200) {
        this.noticia = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.url = `http://aquidauana.sistemaweb.com.br${window.location.pathname}`;
    this.getNoticia(this.$route.params.id);
    this.getDestaques();
  },
  beforeRouteUpdate(to, from, next) {
    this.getNoticia(to.params.id);
    next();
  },
};
</script>

<style lang="scss">
.noticias {
  display: flex;
  padding: 0;
  font-size: var(--font-size--large);
  .noticia {
    padding-top: 20px;
    &-body {
      display: flex;
      flex-wrap: wrap-reverse;
      &__texto {
        padding: 10px;
      }
      img {
        height: auto;
        max-width: 700px;
        object-fit: contain;
        padding: 10px;
      }
      &__images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
        img {
          height: auto;
          width: 290px;
          object-fit: contain;
          padding: 10px;
        }
      }
      &__fontes {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
      }
    }
  }
  .list {
    width: 40%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-light);
    height: 100%;
    padding-bottom: 20px;
    .lista {
      height: auto;
      text-align: left;
    }
    .see-all {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px 0 0;
    }
    .text-right {
      width: 112px;
      height: 30px;
      background-color: var(--background-color-secondary);
      color: var(--background-color-primary);
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      display: flex;
      &:active,
      &:hover {
        background-color: var(--background-color-hover-buttons) !important;
        text-decoration: none;
      }
    }
  }
}
@media only screen and (max-width: 936px) {
  .noticias {
    .noticia {
      padding-top: 20px;
      &-body {
        display: flex;
        flex-direction: column-reverse;
        img {
          height: auto;
          max-width: 100%;
          object-fit: contain;
          padding: 10px;
        }
        &__images {
          display: flex;
          flex-direction: column;
          height: 100%;
          img {
            height: auto;
            width: 100%;
            object-fit: contain;
            padding: 5px;
          }
        }
      }
    }
    .list {
      display: none;
    }
  }
}
</style>
